//==================PORTAL ROUTES=======================//
export const PORTALHOME = '/';
export const PROFILE = '/perfil';
export const SCHEDULE = '/agenda';
export const FILES = '/arquivos';
export const REPORTS = '/reports';
export const HEALTHCHECK = '/health-check';
export const TAXINFORMATION = '/tax-information-page';

//===================MAKE BI==================//
export const MAKEBI = '/make-bi';
export const QSEARCHBAR = '/make-bi/q-searchbar';

//=================MAKE THE PRICE CLIENTE ROUTES==================//
export const MTPHOME = '/mtp/home/';
export const MTPFICHADETAILS = ':id';

//================ELEMENTAR ROUTES======================//
export const ELEMENTARHOME = '/elementar/home/';
export const SPEDDETAILS = ':id';
export const SPEDCRUZAMENTOS = 'cruzamentos/:id';
export const SPEDANALISE = 'analise/:id';
export const SPEDQUADROANALITICO = 'quadro-analitico/:id';
export const TESTUPLOAD = '/elementar/test-upload';
export const SAVECOMPANY = '/elementar/save-company';

//=================CADASTRO DE DADOS=======================//
export const CADASTROSBASICOS = '/cadastros-basicos/home/';
export const CADASTROSBASICOSEMPRESAS = 'empresas/:id';
export const CADASTROSBASICOSUSERS = 'usuarios/:id';
export const CADASTROSDASHBOARD = 'painel/:id';
export const CONTROLDASHBOARDS = 'control-dashboards/:id';
export const USERSDASHBOARDS = 'user-dashboards/:id';
export const CADBASCOMPANIESMAKEVIEW = 'empresas-make-view';

//====================OLHO DA MIA========================//
export const OLHODAMIAHOME = '/olho-da-mia/home';

//============MAKE FORWARD | MAKE FORWARD NFSE | MAKE FORWARD CTE=============//
export const NOTAAVULSAHOME = '/make-forward';
export const MAKEFORWARDNFSE = '/make-forward-nfse';
export const MAKEFORWARDCTE = '/make-forward-cte';
export const MAKEFORWARDINTEGRATIONS = '/integrations-management';
export const NOTAAVULSATAGS = 'tag-management';
export const MAKEFORWARDTAGS = 'tag-management/:invoice';
export const NOTAAVULSAANALYSIS = ':id';
export const NOTAAVULSAANALYSISSUBST = 'subst/:id';
export const NOTAAVULSADETAILS = 'details/:id/:type';
// export const NFSEDETAILS = 'nfse/:id/:type';
export const MFINVOICEERRORS = 'invoice-errors/:id/:type';

//=================SUBIDA DE DOCUMENTOS==================//
export const SUBIDADEDOCUMENTOS = '/subida-documentos';
export const SUBIDADEDOCUMENTOSDE = '/upload-documents-de';
export const SUBIDADEDOCUMENTOSMAKEVIEW = '/subida-documentos-make-view';

//=================SOLICITAR DOCUMENTOS==================//
export const SOLICITARDOCUMENTOS = '/solicitar-documentos';

//=================DEFINIR CATEGORIAS==================//
export const DEFINIRCATEGORIAS = '/definir-categorias';

//=================MAKE VIEW==================//
export const MAKEVIEW = '/make-view';
export const MAKEVIEWFILES = '/make-view-files';
export const MAKEVIEWDASHBOARDS = '/make-view-dashboards';

//=================BASE LEGAL==================//
export const BASELEGAL = '/base-legal';
export const NCMSELECTOR = '/ncm-selector';

//=================DLM==================//
export const LIFECYCLE = '/dlm';
export const DLMWORKFLOWS = '/dlm-workflows';
